<template>
    <div class="search">
        <div class="searchTitle">
            <!-- <div class="back" @click="back">返回</div> -->
            <div class="center">
                <i class="iconfont icon-shanchu2"></i>
                <input type="text" v-model="searchValue" placeholder="店铺名称">
            </div>
            <div class="right" @click="search">取消</div>
        </div>
        <div class="history" v-show="action==1">
            <div class="searchHistory" v-if="historyList.length!=0">
                <div class="left">搜索历史</div>
                <div class="right" @click="clearHistory" >
                    <i class="iconfont icon-shanchu2"></i>
                </div>
            </div>
            <ul class="historyList" v-if="historyList.length!=0">
               <li v-for="item in historyList" @click="search(item)">{{item}} 
                   <i class="iconfont icon-xiaohaoshanchu"></i>
                </li>
            </ul>
            <div class="noHistory" v-if="historyList.length == 0">
                <img src="../../../assets/search.png" alt="">
                暂无搜索历史</div>
        </div>
        <div class="list" v-show="action==2">
            <!-- <label-list></label-list> -->
            <case-list></case-list>
        </div>

        
    </div>
</template>
<script>
import labelList from '../components/labelList'
import caseList from '../components/caseList'
export default {
    name:'search',
    components:{
        labelList,caseList
    },
    data(){
        return{
            action:2,
            // historyList:['每天听见吴晓波','听闻','萧瑟','惆怅估值'],
            historyList:[],
            searchValue:'',
        }
    },
    methods:{
        back(){
            this.$router.go(-1);
        },
        clearHistory(){
            this.historyList = [];
        },
        search(item){  //搜索某项
            
        }
    }
}
</script>
<style lang="scss" scoped>
.search{
    width:100%;
    height:100vh;
    background: #F5F5F5;
    .searchTitle{
        background: #fff;
        display: flex;
        align-items: center;
        padding:.16rem .24rem .16rem .4rem;
        // border-bottom:1px solid #bfbfbf;
        // .back{
        //     width:30px;
        //     font-size:14px;
        // }
        .center{
            flex:1;
            background: #F5F5F5;
            border-radius: .4rem;
            height:.8rem;
            line-height: .8rem;
            align-items: center;
            i{
                font-size:.34rem;
                display: inline-block;
                margin-left:.28rem;
                color:#333;
            }
            input{
                // display: inline-block;
                width:80%;
                border:none;
                background: #f5f5f5;
                text-indent:.14rem;
                height:.6rem;
                line-height: .6rem;
                &:focus{
                    outline:none
                }
            }
        }
        .right{
            width:.96rem;
            font-size:.28rem;
            text-indent:.32rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
           
        }
    }
    .history{
        width:$defaultWidth;
        margin:.32rem auto;
        .searchHistory{
            display: flex;
            font-size:.32rem;
            .left{
                flex:1;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
            .right{
                width:36px;
                text-align: right;
                i{
                    font-size:.32rem;
                    display: inline-block;
                    margin-top:.05rem;
                }
            }

        }
        .historyList{
            margin-top: .32rem;
            li{
                display: inline-block;
                list-style: none;
                padding:.08rem .16rem;
                background: #fff;
                font-size:.24rem;
                color:#999999;
                border-radius: .26rem;
                margin-right:.24rem;
                margin-bottom:.22rem;
                i{
                    font-size:.26rem;
                }
            }

        }
        .noHistory{
            width:100%;
            text-align: center;
            padding-top:40px;
            color:#333333;
            font-size:.32rem;
            img{
                display: block;
                width:1.78rem;
                height:1.76rem;
                margin:2.44rem auto .32rem;
            }
        }
    }
    .list{
        width:100%
    }
}
  
    
</style>